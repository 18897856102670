import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
              <path d="M5160 10320 c-671 -30 -1382 -219 -1995 -530 -319 -162 -672 -396
-916 -605 -19 -16 -62 -52 -94 -80 -106 -90 -424 -411 -495 -501 -246 -307
-235 -292 -365 -489 -198 -300 -321 -538 -465 -895 -171 -427 -272 -844 -326
-1355 -24 -220 -24 -657 -1 -895 17 -166 54 -426 78 -535 137 -631 341 -1133
686 -1683 37 -59 71 -111 75 -117 5 -5 53 -71 109 -145 292 -394 592 -696
1004 -1011 170 -130 451 -306 675 -422 709 -369 1478 -557 2270 -557 761 0
1508 179 2200 525 126 64 188 97 348 191 136 78 445 299 587 419 474 398 868
872 1148 1382 67 120 157 296 176 343 8 19 27 62 42 95 88 193 204 538 267
795 261 1062 156 2187 -297 3188 -82 182 -236 472 -301 567 -15 22 -38 58 -52
80 -38 61 -48 77 -112 167 -55 77 -218 289 -241 314 -9 9 -87 98 -138 158 -31
35 -150 156 -234 237 -210 202 -466 407 -693 553 -41 27 -91 59 -110 72 -128
84 -414 235 -600 317 -579 253 -1158 388 -1790 417 -118 5 -228 9 -245 8 -16
-1 -104 -4 -195 -8z m505 -285 c209 -9 474 -43 695 -91 759 -164 1452 -502
2034 -994 157 -132 413 -385 521 -515 28 -33 58 -69 69 -80 35 -39 243 -321
300 -409 221 -339 398 -709 525 -1096 109 -335 159 -564 208 -955 22 -180 25
-781 5 -950 -21 -168 -60 -427 -78 -502 -101 -447 -223 -790 -407 -1152 -240
-472 -530 -861 -927 -1246 -279 -271 -585 -499 -930 -694 -91 -52 -320 -166
-400 -200 -41 -18 -84 -36 -95 -41 -51 -24 -293 -111 -410 -148 -271 -85 -519
-137 -866 -181 -264 -34 -762 -34 -1028 0 -120 15 -337 48 -391 60 -798 172
-1410 461 -2033 958 -144 115 -489 453 -608 596 -91 108 -241 307 -295 390
-21 33 -41 62 -44 65 -6 6 -14 18 -101 164 -85 141 -191 345 -249 476 -23 52
-48 109 -55 125 -61 136 -176 489 -224 685 -279 1150 -106 2376 478 3400 108
189 290 459 393 584 342 414 684 729 1068 983 483 320 1021 547 1590 672 317
69 787 117 1040 105 41 -2 138 -6 215 -9z"/>
<path d="M5195 9990 c-793 -33 -1587 -282 -2255 -707 -132 -84 -322 -219 -405
-287 -557 -458 -942 -934 -1254 -1553 -39 -77 -71 -142 -71 -146 0 -4 -13 -35
-29 -69 -42 -93 -94 -227 -140 -363 -268 -794 -313 -1637 -132 -2465 93 -424
267 -876 484 -1257 26 -45 47 -87 47 -92 0 -5 4 -11 8 -13 4 -1 34 -46 67 -98
253 -405 612 -807 1006 -1126 47 -38 96 -77 110 -87 208 -150 381 -262 541
-351 113 -62 275 -142 336 -166 31 -13 62 -27 68 -31 15 -12 233 -96 349 -134
360 -120 641 -179 1079 -225 339 -36 779 -17 1181 51 312 53 759 184 999 293
28 13 80 36 116 52 69 30 258 125 325 163 105 60 138 80 250 150 66 41 122 77
125 81 3 4 28 22 55 41 54 37 157 114 215 161 19 16 40 32 45 36 65 45 422
389 525 507 131 148 318 397 432 575 149 231 341 611 408 804 10 30 25 70 33
88 32 73 89 260 138 448 225 876 179 1839 -130 2680 -91 250 -223 541 -312
690 -14 25 -48 83 -74 129 -91 158 -328 486 -449 621 -401 448 -749 734 -1217
1003 -355 204 -784 375 -1174 467 -71 17 -148 35 -170 40 -160 38 -466 77
-670 85 -82 3 -179 7 -215 9 -36 2 -146 0 -245 -4z m1050 -1404 c246 -64 514
-254 714 -506 37 -47 82 -109 100 -138 40 -66 161 -314 161 -330 0 -6 5 -14
12 -18 8 -5 10 10 5 59 -11 119 -104 331 -199 456 -80 106 -98 136 -98 168 0
20 5 33 13 33 23 0 160 -97 223 -156 81 -79 148 -169 201 -271 77 -149 93
-203 99 -343 6 -122 1 -168 -27 -237 -16 -39 -16 -39 108 -103 47 -25 101 -59
122 -76 31 -27 112 -148 159 -238 7 -14 29 -38 49 -55 38 -31 65 -38 266 -60
155 -17 188 -38 431 -265 168 -155 195 -186 202 -224 14 -73 -14 -165 -79
-266 -18 -28 -51 -89 -74 -136 -57 -118 -115 -169 -225 -204 -9 -3 -30 -29
-47 -58 -88 -157 -152 -231 -254 -296 -59 -37 -180 -78 -247 -83 l-45 -4 1 35
c1 19 18 71 39 115 48 102 55 158 26 218 -29 59 -61 72 -177 72 -86 0 -95 -2
-169 -39 -158 -81 -266 -185 -352 -342 -6 -12 -26 -86 -44 -165 -24 -106 -32
-170 -33 -245 -1 -96 0 -102 29 -144 17 -24 53 -67 80 -95 l50 -52 87 8 c133
12 208 42 312 123 85 66 88 64 80 -46 -11 -165 -41 -310 -90 -441 -16 -41 -17
-50 -4 -70 32 -51 40 -74 40 -119 0 -115 -75 -253 -212 -391 -108 -108 -133
-128 -215 -174 -76 -43 -133 -66 -213 -87 -73 -19 -310 -59 -316 -54 -2 3 5
38 16 79 11 41 20 88 20 105 0 28 -4 33 -40 42 -22 5 -42 6 -45 2 -3 -5 -4
-84 -3 -177 2 -198 -13 -268 -88 -414 -46 -88 -164 -238 -240 -303 -97 -83
-377 -237 -519 -286 -50 -18 -192 -50 -217 -50 -16 0 -7 59 17 106 41 81 88
204 81 212 -4 3 -10 2 -14 -4 -17 -24 -264 -157 -327 -176 -11 -4 -65 -23
-120 -43 -99 -36 -153 -52 -270 -81 -120 -30 -142 -34 -275 -59 -74 -14 -147
-28 -161 -31 -33 -7 -39 2 -28 41 7 24 29 42 106 89 550 334 618 378 818 535
190 150 487 439 565 551 13 19 29 40 35 46 6 7 40 66 76 133 35 66 69 121 75
121 23 0 32 -159 14 -247 -17 -79 -60 -360 -60 -385 1 -20 8 -15 56 37 109
118 153 203 174 337 13 80 11 149 -10 413 -22 263 2 614 51 743 21 56 6 56
-50 0 -82 -83 -120 -172 -187 -439 -76 -307 -168 -479 -318 -593 -55 -42 -76
-52 -231 -108 -172 -62 -436 -109 -434 -77 3 41 32 76 230 276 200 202 354
386 403 481 26 51 65 160 127 361 70 223 115 334 182 445 27 43 46 80 44 82
-2 2 -81 -39 -176 -92 -95 -53 -176 -92 -181 -88 -16 18 42 240 164 624 25 80
46 151 46 158 0 28 -253 -23 -417 -84 -54 -20 -172 -74 -263 -119 -91 -46
-167 -82 -169 -80 -15 15 22 94 93 199 204 302 416 555 532 633 112 77 553
276 635 287 25 4 70 2 100 -4 65 -14 219 -88 274 -132 56 -45 175 -169 240
-248 48 -59 54 -71 49 -98 l-6 -32 -102 7 c-95 5 -243 -3 -233 -13 3 -3 46
-14 96 -24 92 -19 203 -52 231 -70 12 -8 13 -14 1 -46 -8 -21 -28 -58 -46 -83
-67 -94 -124 -226 -271 -631 -23 -66 -64 -223 -64 -250 0 -24 20 10 137 240
61 118 122 234 135 258 41 71 145 197 210 254 33 29 65 58 72 64 16 15 148 94
158 94 4 0 8 4 8 8 0 4 -16 6 -35 4 -20 -2 -60 -1 -90 3 -52 6 -55 8 -55 35 0
27 8 33 109 83 147 74 183 98 266 177 91 86 122 102 214 108 l74 5 -7 -28 c-4
-16 -32 -55 -62 -88 -46 -51 -54 -66 -54 -99 0 -44 27 -102 62 -135 43 -40 88
-51 239 -58 190 -9 295 4 352 44 44 30 83 90 74 114 -3 7 -33 22 -67 32 -107
32 -263 129 -362 224 -38 36 -48 52 -48 78 0 17 4 34 9 37 5 3 51 -5 102 -19
70 -18 120 -25 195 -25 55 0 103 3 107 6 7 8 -97 110 -170 166 -29 22 -73 46
-99 54 -25 7 -146 21 -268 29 -121 9 -231 18 -243 20 -35 8 -28 -6 33 -65 71
-69 114 -127 108 -146 -14 -46 -10 -44 -61 -29 -127 39 -329 85 -372 85 l-28
0 5 -69 c5 -74 -7 -124 -39 -165 -28 -35 -35 -33 -43 15 -13 84 -122 249 -225
342 -43 38 -308 207 -326 207 -5 0 -36 24 -60 47 -7 6 -10 25 -7 43 5 32 5 32
46 24 60 -12 137 -10 256 5 190 25 332 -1 383 -70 11 -16 23 -29 26 -29 3 0
20 12 36 26 26 22 30 33 31 77 2 97 4 107 18 107 32 0 106 -117 106 -168 0
-16 -11 -51 -25 -77 l-24 -49 47 -43 c54 -48 62 -51 62 -23 0 14 14 27 45 42
47 23 55 37 30 58 -8 7 -40 54 -71 105 -30 52 -74 111 -97 132 -127 117 -425
227 -779 289 -146 25 -396 49 -406 39 -3 -3 11 -57 32 -119 36 -107 41 -156
16 -156 -6 0 -35 16 -65 36 -163 103 -370 188 -675 273 -80 22 -158 45 -175
50 -16 5 -54 15 -83 22 -51 12 -53 14 -50 43 3 28 8 32 63 49 102 31 244 56
395 68 196 15 205 20 102 53 -128 40 -242 58 -419 63 -157 5 -162 6 -161 27 2
32 16 45 87 81 190 95 464 140 706 116 266 -26 447 -102 707 -298 53 -40 100
-68 103 -63 3 5 1 11 -5 15 -5 3 -10 13 -10 20 0 8 -11 39 -25 69 -14 30 -25
58 -25 61 0 25 -109 181 -180 259 -212 231 -417 346 -692 390 -123 20 -429 54
-551 62 l-98 6 5 30 c2 16 6 31 8 33 6 7 215 75 288 95 66 17 100 19 285 16
187 -3 221 -7 310 -30z m-1185 -271 c165 -17 267 -30 325 -44 77 -18 257 -69
262 -75 4 -3 1 -17 -6 -30 -12 -21 -21 -24 -118 -30 -59 -3 -156 -6 -217 -6
-246 0 -475 -45 -651 -129 -89 -43 -242 -133 -255 -151 -3 -3 -77 -54 -165
-112 -405 -267 -758 -381 -1080 -349 -113 11 -160 25 -282 82 -80 36 -83 39
-83 72 l0 33 53 -7 c103 -15 298 -10 372 10 141 36 231 92 625 390 306 231
379 274 538 317 159 43 429 55 682 29z m-535 -730 c85 -6 332 -42 347 -51 3
-2 0 -17 -7 -32 -13 -25 -24 -29 -132 -51 -139 -28 -231 -61 -408 -146 -222
-108 -261 -139 -560 -439 -236 -238 -398 -386 -421 -386 -4 0 -21 -10 -38 -23
-79 -59 -312 -155 -474 -195 -414 -104 -852 12 -1112 296 -104 113 -169 206
-166 236 1 14 5 29 9 32 3 3 97 -24 209 -59 317 -102 317 -102 523 -102 146 1
198 5 276 22 146 33 314 93 427 151 92 48 593 383 767 512 264 196 418 258
615 244 30 -2 96 -6 145 -9z m664 -284 c20 -5 68 -15 106 -21 125 -22 419 -80
455 -91 85 -26 189 -72 232 -104 45 -33 46 -35 35 -65 -10 -27 -16 -31 -38
-26 -100 20 -389 38 -524 32 -154 -6 -314 -27 -359 -47 -35 -16 -38 -28 -47
-169 -10 -168 14 -470 38 -470 6 0 23 19 52 55 51 67 204 216 254 248 15 10
27 22 27 27 0 19 -70 75 -137 111 -59 31 -68 40 -71 66 -3 30 -2 31 61 42 136
24 315 5 467 -49 72 -25 103 -25 176 2 81 29 294 31 439 4 127 -24 152 -33
137 -48 -7 -7 -12 -19 -12 -28 0 -12 -22 -23 -72 -39 -40 -13 -82 -26 -93 -31
-11 -4 -84 -33 -161 -64 -292 -115 -445 -191 -553 -273 -36 -27 -92 -76 -215
-188 -6 -5 -38 -32 -71 -60 -89 -74 -185 -172 -235 -240 -25 -33 -47 -62 -51
-65 -3 -3 -28 -34 -55 -70 -28 -36 -71 -92 -97 -125 -63 -83 -233 -261 -241
-254 -9 8 26 164 69 312 28 98 49 146 124 277 50 87 91 168 91 180 0 48 -22
110 -39 110 -96 0 -389 -87 -477 -141 -76 -47 -68 -9 52 230 51 102 92 186 91
187 -14 14 -604 -284 -709 -357 -38 -28 -49 -24 -41 13 13 68 187 341 313 493
14 16 63 70 110 118 129 135 240 210 398 272 92 36 83 40 -105 50 l-143 7 0
30 c0 26 6 32 54 53 81 36 163 61 257 79 46 8 104 20 129 25 60 12 333 13 379
2z m1888 -173 c45 -21 127 -89 143 -118 10 -19 -5 -70 -20 -70 -4 0 -33 9 -66
21 -42 14 -95 22 -181 26 l-123 6 0 28 c0 16 14 46 33 71 25 33 43 46 77 54
51 13 78 10 137 -18z m-3439 -680 c-21 -63 -162 -272 -227 -338 -100 -102
-216 -174 -382 -240 -244 -97 -383 -191 -458 -309 -49 -76 -46 -104 56 -529 8
-35 16 -62 18 -60 1 2 31 50 66 108 130 216 183 283 277 347 115 78 378 183
637 254 136 37 156 42 161 36 14 -14 -30 -72 -106 -136 -450 -383 -515 -454
-660 -726 -29 -55 -72 -136 -96 -180 -77 -144 -121 -281 -143 -448 -6 -43 -11
-207 -11 -365 0 -277 -4 -332 -20 -332 -17 0 -174 292 -269 502 -111 246 -336
813 -369 933 -54 194 -67 389 -36 567 37 217 94 326 210 400 107 69 176 95
444 168 294 81 545 182 766 310 66 37 127 68 136 69 15 1 16 -3 6 -31z m748
-868 c-22 -127 -69 -265 -138 -410 -71 -149 -92 -199 -86 -206 3 -2 59 22 124
55 179 89 391 147 509 139 46 -3 50 -5 47 -28 -2 -16 -63 -84 -196 -215 -106
-104 -231 -232 -277 -284 -46 -52 -121 -135 -167 -185 -308 -338 -357 -510
-346 -1215 5 -335 2 -383 -24 -367 -16 10 -154 257 -210 378 -72 154 -115 300
-168 573 -26 128 -26 436 -1 559 36 175 91 322 176 470 45 79 133 201 183 256
13 14 52 59 88 100 35 41 67 77 70 80 3 3 48 55 100 115 52 61 109 126 126
145 17 19 69 77 115 129 l84 95 3 -47 c2 -26 -4 -87 -12 -137z m1214 -421 c0
-32 -16 -56 -109 -164 -48 -55 -102 -120 -121 -145 -19 -25 -37 -47 -40 -50
-44 -41 -178 -245 -230 -349 -89 -177 -114 -285 -130 -549 -31 -524 -105 -791
-277 -993 -41 -49 -138 -135 -248 -222 -71 -57 -216 -148 -295 -186 -70 -33
-261 -111 -273 -111 -2 0 -2 15 1 33 4 26 66 113 112 157 3 3 75 82 160 175
85 94 157 172 160 175 70 66 174 213 209 295 44 103 127 513 108 532 -9 8 -96
-60 -178 -139 -69 -67 -135 -149 -188 -235 -18 -29 -37 -53 -42 -53 -5 0 -9
73 -9 168 0 199 12 255 96 462 83 202 168 370 247 489 116 175 332 379 553
524 76 49 207 120 239 130 11 3 47 15 80 27 62 23 141 47 163 49 6 0 12 -8 12
-20z m-3430 -1779 c19 -23 46 -58 59 -76 l24 -34 -35 -161 -35 -160 169 2 c93
1 173 -2 177 -7 74 -85 152 -186 149 -192 -1 -5 -46 -44 -98 -87 -52 -43 -97
-81 -100 -84 -3 -4 -51 -43 -107 -89 l-103 -82 -19 22 c-11 13 -40 47 -65 77
-25 30 -46 60 -45 65 0 6 45 47 100 90 54 44 97 81 95 83 -2 2 -77 0 -167 -6
l-164 -10 -33 37 c-39 44 -39 48 12 237 19 71 33 132 31 134 -2 2 -47 -33 -99
-78 -85 -72 -126 -96 -126 -73 0 4 -22 32 -50 62 -63 70 -69 88 -39 113 13 10
34 27 45 37 12 10 73 60 135 111 63 52 134 111 159 132 l44 38 26 -29 c14 -16
41 -48 60 -72z m6660 -150 c102 -17 207 -35 233 -40 26 -6 61 -10 77 -10 16 0
32 -5 35 -10 6 -9 -112 -171 -135 -185 -5 -3 -28 -1 -51 5 -48 13 -48 13 -129
-95 -25 -33 -53 -68 -62 -79 -16 -18 -16 -21 2 -57 21 -40 26 -75 12 -81 -5
-1 -35 -38 -66 -81 -61 -80 -76 -86 -92 -34 -8 26 -69 165 -125 285 -21 46
-39 86 -39 89 0 3 -14 34 -30 70 -25 54 -28 67 -18 82 7 9 42 54 77 99 59 77
66 82 95 77 17 -2 114 -18 216 -35z m-380 -431 c0 -5 -72 -71 -153 -139 l-30
-26 114 -128 c63 -70 115 -133 117 -140 2 -7 -32 -44 -74 -81 -69 -62 -78 -67
-89 -52 -7 10 -46 54 -87 100 -41 45 -90 99 -108 120 l-32 37 -77 -68 c-117
-103 -104 -99 -144 -55 -61 66 -78 86 -75 93 2 4 44 42 93 86 50 43 110 97
135 120 25 23 101 91 170 152 l125 111 57 -62 c32 -34 57 -65 58 -68z m-5557
-215 c66 -49 78 -71 54 -96 -7 -7 -28 -35 -47 -61 l-34 -48 29 -25 c29 -23 34
-24 120 -18 50 3 110 10 135 14 44 8 47 7 115 -43 39 -28 87 -62 107 -76 20
-14 36 -30 34 -36 -1 -5 -45 -15 -97 -22 -215 -27 -269 -36 -269 -44 0 -5 7
-46 15 -91 9 -46 20 -105 25 -133 5 -27 12 -72 16 -99 8 -56 9 -56 -69 6 -27
21 -52 38 -55 38 -4 0 -26 17 -50 38 -41 35 -44 42 -62 132 -11 52 -19 106
-20 119 0 22 -58 91 -77 91 -5 0 -32 -32 -61 -70 l-53 -70 -77 56 c-42 31 -81
60 -85 64 -5 4 24 52 64 106 228 310 241 326 259 321 9 -2 47 -26 83 -53z
m4834 -245 c21 -12 79 -45 128 -73 50 -29 137 -79 195 -111 58 -33 107 -62
110 -65 3 -4 13 -9 23 -13 10 -4 16 -11 13 -16 -8 -12 -184 -121 -196 -121 -5
0 -25 11 -45 25 l-37 24 -81 -51 c-45 -28 -95 -59 -110 -67 -27 -15 -28 -19
-24 -68 5 -50 5 -52 -31 -75 -103 -68 -153 -98 -160 -98 -5 0 -14 87 -21 193
-6 105 -17 241 -23 301 -6 65 -7 111 -2 113 5 2 50 30 99 63 50 32 98 59 107
59 10 1 34 -9 55 -20z m-544 -349 c63 -24 117 -74 149 -139 18 -36 23 -63 23
-126 0 -70 -4 -86 -28 -127 -15 -26 -42 -59 -60 -72 -33 -26 -469 -206 -497
-206 -18 0 -215 479 -203 491 10 9 307 130 418 170 95 34 127 36 198 9z
m-3045 -100 c139 -39 258 -116 299 -194 25 -47 20 -142 -11 -223 -45 -118
-111 -163 -239 -163 -208 0 -439 126 -465 255 -25 122 48 288 146 331 44 20
188 17 270 -6z m647 -183 l110 -12 88 -130 c49 -71 92 -137 97 -145 5 -8 11
-12 14 -9 3 2 10 59 17 124 6 66 15 123 19 128 5 6 200 -9 207 -16 0 -1 -11
-120 -25 -264 l-27 -263 -50 0 c-99 1 -189 18 -205 38 -8 11 -61 85 -118 166
-57 80 -107 146 -111 146 -5 0 -11 -34 -15 -75 -3 -41 -9 -109 -13 -151 l-6
-77 -66 7 c-36 3 -83 6 -103 6 l-38 0 0 48 c0 26 7 110 15 187 8 77 17 172 21
210 9 109 -8 102 189 82z m1340 -73 c4 -36 5 -67 3 -69 -1 -2 -64 -9 -138 -15
-283 -26 -265 -22 -268 -49 -2 -13 0 -27 5 -31 4 -5 97 1 206 12 109 11 200
18 202 16 1 -1 6 -30 10 -64 l7 -61 -189 -17 c-103 -10 -196 -20 -205 -22 -12
-4 -18 -15 -18 -36 0 -30 0 -30 48 -24 105 13 364 37 367 34 1 -2 5 -33 9 -70
l6 -67 -32 -5 c-18 -3 -134 -14 -258 -26 -124 -11 -251 -23 -282 -26 -56 -6
-58 -5 -58 18 0 12 -11 131 -25 263 -13 132 -21 243 -17 246 4 4 117 17 252
29 135 12 247 24 249 26 2 2 30 4 62 4 l57 0 7 -66z"/>
<path d="M7155 6681 c11 -13 29 -28 40 -33 20 -11 20 -11 0 11 -11 13 -29 28
-40 33 -20 11 -20 11 0 -11z"/>
<path d="M8085 5658 c-2 -7 -10 -47 -18 -88 -9 -41 -18 -83 -22 -92 -25 -62
99 42 156 131 16 25 29 49 29 53 0 14 -140 10 -145 -4z"/>
<path d="M6960 4583 c0 -39 -60 -186 -114 -283 -31 -56 -55 -104 -53 -106 6
-7 79 -8 185 -5 51 2 95 1 98 -2 8 -7 -22 -120 -46 -173 -41 -95 -122 -168
-199 -180 -23 -4 -44 -10 -47 -15 -2 -4 27 -18 67 -30 71 -21 146 -22 248 -3
l34 7 -7 -39 c-4 -22 -20 -58 -36 -81 -16 -23 -26 -44 -22 -47 14 -9 111 33
171 73 56 39 207 200 223 239 13 32 8 74 -12 99 -10 13 -59 68 -108 121 -177
194 -301 341 -341 405 -24 40 -41 48 -41 20z"/>
<path d="M7502 4450 c-35 -17 -67 -30 -73 -30 -19 0 -8 -21 32 -65 23 -25 44
-45 48 -45 8 0 58 107 66 143 9 34 5 33 -73 -3z"/>
<path d="M8642 3063 c2 -5 18 -36 36 -71 l33 -63 41 51 c22 28 39 52 37 54 -5
3 -135 36 -144 36 -4 0 -5 -3 -3 -7z"/>
<path d="M7665 2085 c4 -44 8 -81 10 -83 2 -2 29 13 60 34 l56 37 -48 30 c-26
16 -56 37 -67 46 -18 16 -18 14 -11 -64z"/>
<path d="M6945 1779 c-54 -21 -103 -43 -109 -49 -6 -6 0 -34 17 -77 15 -38 27
-74 27 -81 0 -19 18 -15 130 29 110 44 140 66 140 105 0 55 -50 116 -93 113
-7 0 -57 -18 -112 -40z"/>
<path d="M3967 1702 c-37 -39 -45 -99 -17 -135 12 -15 28 -27 36 -27 8 0 14
-3 14 -8 0 -9 63 -29 118 -38 48 -7 76 6 102 49 43 70 11 126 -93 165 -81 31
-127 29 -160 -6z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
